import React from 'react';
import Routes from './Routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function App() {
    return (
        <Routes />
    );
}